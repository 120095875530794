import React from 'react'
import { Layout } from '../../components/Layout'
import { Wrapper } from '../../components/Wrapper'

import './JoinPage.scss'

export const JoinPage: React.FC = () => {
  return (
    <Layout>
      <Wrapper>
        <div className="join__wrapper">
          <h1>How to register</h1>

          <ol>
            <li>
              Go to{' '}
              <a
                href="https://sportsbook.qa.fndl.dev/"
                target="_blank"
                rel="noreferrer"
              >
                Sportsbook QA{' '}
              </a>
              and click join now
            </li>
            <li>Complete the info</li>
            <li>
              Copy the promo code of the promotion details you were looking for
              and pase it in the promo code text area
            </li>
            <li>Click create account</li>
            <li>
              In the console paste the following
              <pre className="login__code">
                <code>
                  {`function getCookie(name){var nameEQ = name + "=";var ca=document.cookie.split(';');for(var i=0;i<ca.length;i++){var c=ca[i];while(c.charAt(0)==' ') c=c.substring(1,c.length);if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);}return null;}getCookie('X-Auth-Token')`}
                </code>
              </pre>
            </li>
            <li>
              In the demo, select debug at the bottom right and paste the auth
              token into the text area.
            </li>
            <li>Select save</li>
          </ol>
        </div>
      </Wrapper>
    </Layout>
  )
}
