import { AppName, RouteHandlerTypes } from '@fanduel/cpp-fe-promotions'
import React, { FormEvent, useContext, useState } from 'react'
import Modal from 'react-modal'

import { DebugContext } from '../../contexts/DebugContext'
import { BASE_URL_BTNS, getQaUrl } from '../../constants'
import pkg from '../../../package.json'

import './debugMenuModalStyle.scss'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '100vh',
  },
  overlay: { zIndex: 1000 },
}

Modal.setAppElement('#root')

export const DebugMenuModal: React.FC = () => {
  const {
    isOpen,
    onClose,
    appName,
    baseUrl,
    routeConfig,
    placementQueryConfig,
    updateConfig,
    clearConfig,
    authToken,
    locationConfig,
    isLoggedIn,
  } = useContext(DebugContext)

  const initialState = {
    appName,
    baseUrl,
    placementQueryConfig,
    routeConfig,
    authToken,
    isLoggedIn,
    locationConfig,
    isDirty: false,
  }

  const intialErrorState = {
    appName: false,
    baseUrl: false,
    placementQueryConfig: false,
    locationConfig: false,
    [RouteHandlerTypes.LOGIN]: false,
    [RouteHandlerTypes.JOIN]: false,
    [RouteHandlerTypes.VIEW_PROMOTION]: false,
    [RouteHandlerTypes.PROMOTIONS]: false,
    [RouteHandlerTypes.ROOT]: false,
  }

  const [debugMenuState, setDebugMenuState] = useState<any>(initialState)
  const [errors, setErrors] = useState(intialErrorState)

  const submitDebug = (event: FormEvent<HTMLFormElement>) => {
    // TODO: The validation needs to be less manual but will do for now

    event.preventDefault()
    const errorArr: string[] = []
    try {
      JSON.parse(debugMenuState.placementQueryConfig)
    } catch (e) {
      console.error(e)
      errorArr.push('placementQueryConfig')
    }

    const keys = ['appName', 'baseUrl']
    keys.forEach((key) => {
      if (!debugMenuState[key]) {
        errorArr.push(key)
      }
    })

    const routeKeys = [
      RouteHandlerTypes.LOGIN,
      RouteHandlerTypes.JOIN,
      RouteHandlerTypes.VIEW_PROMOTION,
    ]

    routeKeys.forEach((key) => {
      if (!debugMenuState.routeConfig.routes[key]) {
        errorArr.push(key)
      }
    })

    if (errorArr.length === 0) {
      updateConfig({
        ...debugMenuState,
      })
    } else {
      const mapObj: any = {}
      errorArr.forEach((key) => {
        mapObj[key] = true
      })
      setErrors({
        ...errors,
        ...mapObj,
      })
    }
  }

  const onReqClose = () => {
    if (debugMenuState.isDirty) {
      const confirmed = confirm(
        `Your changes aren't saved, are you sure you want to close?`
      )
      if (!confirmed) {
        return
      }
    }
    setDebugMenuState(initialState)
    setErrors(intialErrorState)
    onClose()
  }

  const handleRadioChange = (e: any) => {
    setDebugMenuState({
      ...debugMenuState,
      isDirty: true,
      isLoggedIn: e.target.value === 'true',
    })
  }

  const handleLocationValueChange = (e: any) => {
    setDebugMenuState({
      ...debugMenuState,
      isDirty: true,
      locationConfig: {
        value: e.target.value,
      },
    })
  }

  const handleChange = (e: any) => {
    if (
      e.target.name === 'appName' &&
      !debugMenuState.baseUrl.includes('localhost')
    ) {
      setDebugMenuState({
        ...debugMenuState,
        isDirty: true,
        appName: e.target.value,
        baseUrl: getQaUrl(e.target.value),
      })
      setErrors({
        ...errors,
        appName: false,
        baseUrl: false,
      })
    } else {
      setDebugMenuState({
        ...debugMenuState,
        isDirty: true,
        [e.target.name]: e.target.value,
      })
      setErrors({
        ...errors,
        [e.target.name]: false,
      })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Example Modal"
      onRequestClose={onReqClose}
    >
      <div className="debug-modal">
        <div className="debug-modal__header">
          <h2>Debug Menu</h2>
          <button className="debug-modal__close-btn" onClick={onReqClose}>
            X
          </button>
        </div>
        <p className="debug-modal__version">
          CPP FE Promotions v{pkg.dependencies['@fanduel/cpp-fe-promotions']}
        </p>
        <form onSubmit={submitDebug}>
          <fieldset>
            <label>
              <span>App</span>
              <select
                name="appName"
                value={debugMenuState.appName}
                onChange={handleChange}
              >
                <option value={AppName.FANDUEL_CASINO}>Casino</option>
                <option value={AppName.STARDUST_CASINO}>Stardust</option>
                <option value={AppName.MOHEGANSUN_CASINO}>Mohegansun</option>
              </select>
            </label>
            {errors.appName && <p style={{ color: 'red' }}>Not valid value</p>}
          </fieldset>
          <fieldset>
            <label>
              <span>API Base URL</span>
              <input
                type="text"
                name="baseUrl"
                className="base-url__input"
                value={debugMenuState.baseUrl}
                onChange={handleChange}
              />
              {BASE_URL_BTNS(debugMenuState.appName).map((btn) => (
                <button
                  key={btn.id}
                  className="base-url__btn"
                  onClick={(e) => {
                    e.preventDefault()
                    handleChange({
                      target: {
                        name: 'baseUrl',
                        value: btn.url,
                      },
                    })
                  }}
                >
                  {btn.label}
                </button>
              ))}
            </label>
            {errors.baseUrl && <p style={{ color: 'red' }}>Not valid value</p>}
          </fieldset>
          <fieldset>
            <label>
              <span>Placement Query Config</span>
              <textarea
                name="placementQueryConfig"
                rows={8}
                value={debugMenuState.placementQueryConfig}
                onChange={handleChange}
              />
            </label>

            {errors.placementQueryConfig && (
              <p style={{ color: 'red' }}>Not valid JSON</p>
            )}
          </fieldset>
          <fieldset>
            <label>
              <span>Location Config</span>
              <input
                type="text"
                name="locationConfig"
                value={debugMenuState.locationConfig.value}
                onChange={handleLocationValueChange}
              />
            </label>

            {errors.locationConfig && (
              <p style={{ color: 'red' }}>Not valid value</p>
            )}
          </fieldset>
          <fieldset>
            <span
              style={{
                display: 'block',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '5px',
              }}
            >
              Routes
            </span>
            {[
              RouteHandlerTypes.JOIN,
              RouteHandlerTypes.LOGIN,
              RouteHandlerTypes.PROMOTIONS,
              RouteHandlerTypes.ROOT,
              RouteHandlerTypes.VIEW_PROMOTION,
            ].map((route) => (
              <>
                <label>
                  <span style={{ fontSize: '14px' }}>{route}</span>
                  <input
                    type="text"
                    name={`${route}RouteHref`}
                    value={debugMenuState.routeConfig.routes[route].href}
                    onChange={(e) => {
                      const value = e.target.value
                      setDebugMenuState({
                        ...debugMenuState,
                        isDirty: true,
                        routeConfig: {
                          ...debugMenuState.routeConfig,
                          routes: {
                            ...debugMenuState.routeConfig.routes,
                            [route]: {
                              ...debugMenuState.routeConfig.routes[route],
                              href: value,
                            },
                          },
                        },
                      })
                      setErrors({
                        ...errors,
                        [route]: false,
                      })
                    }}
                  />
                  <input
                    type="text"
                    name={`${route}RouteLabel`}
                    value={debugMenuState.routeConfig.routes[route].label}
                    onChange={(e) => {
                      const value = e.target.value
                      setDebugMenuState({
                        ...debugMenuState,
                        isDirty: true,
                        routeConfig: {
                          ...debugMenuState.routeConfig,
                          routes: {
                            ...debugMenuState.routeConfig.routes,
                            [route]: {
                              ...debugMenuState.routeConfig.routes[route],
                              label: value,
                            },
                          },
                        },
                      })
                      setErrors({
                        ...errors,
                        [route]: false,
                      })
                    }}
                  />
                </label>
                {errors[route] && (
                  <p style={{ color: 'red' }}>Not valid value</p>
                )}
              </>
            ))}
          </fieldset>
          <fieldset>
            <div className="radio-container">
              <input
                id="isLoggedInTrue"
                type="radio"
                value="true"
                onChange={handleRadioChange}
                name="isLoggedIn"
                checked={debugMenuState.isLoggedIn}
              />
              <label htmlFor="isLoggedInTrue">Logged in</label>
            </div>
            <div className="radio-container">
              <input
                id="isLoggedInFalse"
                type="radio"
                value="false"
                onChange={handleRadioChange}
                name="isLoggedIn"
                checked={!debugMenuState.isLoggedIn}
              />
              <label htmlFor="isLoggedInFalse">Logged out</label>
            </div>
          </fieldset>
          <p className="radio-result">
            You are: {debugMenuState.isLoggedIn ? 'logged in' : 'logged out'}
          </p>

          <fieldset>
            <label>
              <span>Auth Token</span>
              <textarea
                name="authToken"
                rows={8}
                value={debugMenuState.authToken}
                onChange={handleChange}
              />
            </label>
          </fieldset>
          <fieldset>
            <button onClick={clearConfig} type="button">
              Clear config
            </button>
          </fieldset>
          <fieldset>
            <button type="submit">Submit</button>
          </fieldset>
        </form>
      </div>
    </Modal>
  )
}
