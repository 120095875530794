import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { LandingPage } from './pages/LandingPage'
import { HubPage } from './pages/HubPage'
import { LoginPage } from './pages/LoginPage'
import { JoinPage } from './pages/JoinPage'
import { DebugButton } from './components/DebugButton'
import { DebugContextProvider } from './contexts/DebugContext'
import { DebugMenuModal } from './components/DebugMenuModal'

function App() {
  return (
    <DebugContextProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={HubPage} />
          <Route path="/login" exact component={LoginPage} />
          <Route path="/join" exact component={JoinPage} />
          <Route path="/:promoCode" exact component={LandingPage} />
        </Switch>
      </BrowserRouter>
      <DebugButton />
      <DebugMenuModal />
    </DebugContextProvider>
  )
}

export default App
