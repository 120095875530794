import React from 'react'

import './footer.scss'

export const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__body">
          <h2 className="footer__body--text">&copy; Copyright FD Bets 2021</h2>
        </div>
      </div>
    </div>
  )
}
