import { PromotionsLandingWeb } from '@fanduel/cpp-fe-promotions/dist'
import React from 'react'

import { Layout } from '../../components/Layout'
import { Wrapper } from '../../components/Wrapper'
import { useConfig } from '../../utils/useConfig'

export const LandingPage: React.FC = () => {
  const { config } = useConfig()
  return (
    <Layout>
      <Wrapper>
        <PromotionsLandingWeb config={config} />
      </Wrapper>
    </Layout>
  )
}
