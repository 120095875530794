import React, { useContext } from 'react'
import { DebugContext } from '../../contexts/DebugContext'

import './header.scss'

export const Header: React.FC = () => {
  const { isLoggedIn } = useContext(DebugContext)
  return (
    <div className="header">
      <div className="header__container">
        <a className="header__link" href="/">
          <h1 className="header__link--name">FD Bets</h1>
        </a>
        <span className="header__logged-state">
          {isLoggedIn ? 'Logged in' : 'Logged out'}
        </span>
      </div>
    </div>
  )
}
