import { AppName } from '@fanduel/cpp-fe-promotions'

export const LOCAL_STORAGE_KEY = 'cppFePromotionsDemoConfig'

export const BASE_URL_BTNS = (appName: AppName) => {
  return [
    {
      id: 1,
      label: 'Local',
      url: 'http://localhost:5005',
    },
    {
      id: 2,
      label: `${appName} QA`,
      url: getQaUrl(appName),
    },
  ]
}

export const getQaUrl = (appName: AppName) => {
  switch (appName) {
    case AppName.STARDUST_CASINO:
      return 'https://promos-api.stardustcasino.fanduel.nxt.fndl.dev'
    case AppName.MOHEGANSUN_CASINO:
      return 'https://promos-api.mohegansun.fanduel.nxt.fndl.dev'
  }
  return 'https://promos-api.casino.fanduel.nxt.fndl.dev'
}
