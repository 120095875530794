import { AppConfig } from '@fanduel/cpp-fe-promotions'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { DebugContext } from '../contexts/DebugContext'
import { eventsHandler } from './eventsHandler'

export const useConfig = (): { config: AppConfig } => {
  const { promoCode } = useParams<{ promoCode: string }>()
  const config = useContext(DebugContext)

  const parsedConfig = {
    appName: config.appName,
    baseUrl: config.baseUrl,
    placementQueryConfig: JSON.parse(config.placementQueryConfig),
    routeConfig: config.routeConfig,
    location: config.locationConfig,
    isLoggedIn: config.isLoggedIn,
    promoCode: promoCode || undefined,
    events: eventsHandler,
  }
  return { config: parsedConfig }
}
