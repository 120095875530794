import React, { useContext } from 'react'
import { DebugContext } from '../../contexts/DebugContext'

export const DebugButton: React.FC = () => {
  const { onOpen } = useContext(DebugContext)
  return (
    <button
      data-test-id="debug-button"
      style={{ position: 'fixed', bottom: '10px', right: '10px' }}
      onClick={onOpen}
    >
      Debug
    </button>
  )
}
