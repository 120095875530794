import React, { useContext } from 'react'
import { Footer } from '../Footer'
import { Header } from '../Header'

import './layout.scss'
import { DebugContext } from '../../contexts/DebugContext'

export const Layout: React.FC = ({ children }) => {
  const { appName } = useContext(DebugContext)
  const classes = `layout ${appName}`
  return (
    <div className={classes}>
      <Header />
      <div className="layout__content">{children}</div>
      <Footer />
    </div>
  )
}
