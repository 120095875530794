import {
  AppName,
  RouteHandlerTypes,
  LocationConfig,
  RouteHandler,
} from '@fanduel/cpp-fe-promotions'
import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { getQaUrl, LOCAL_STORAGE_KEY } from '../constants'
import { isNullOrUndefined } from '../utils/helpers'

export interface DebugOptions {
  onOpen(): void
  onClose(): void
  updateConfig(newDebugState: Partial<DebugState>): void
  clearConfig(): void
}

export interface DebugState {
  isOpen: boolean
  appName: AppName
  baseUrl: string
  locationConfig: LocationConfig
  placementQueryConfig: string
  routeConfig: {
    routes: Record<RouteHandlerTypes, RouteHandler>
  }
  authToken: string
  isLoggedIn: boolean
}

const defaultState: DebugState = {
  isOpen: false,
  appName: AppName.FANDUEL_CASINO,
  baseUrl: getQaUrl(AppName.FANDUEL_CASINO) || '',
  locationConfig: {
    value: 'WV',
  },
  placementQueryConfig: JSON.stringify(
    {
      brand: 'FANDUEL',
      containers: [
        {
          id: '678',
        },
      ],
    },
    undefined,
    2
  ),
  routeConfig: {
    routes: {
      [RouteHandlerTypes.LOGIN]: { href: '/login', label: 'Login' },
      [RouteHandlerTypes.VIEW_PROMOTION]: {
        href: '/:promoCode',
        label: 'Promotion',
      },
      [RouteHandlerTypes.JOIN]: { href: '/join', label: 'Join' },
      [RouteHandlerTypes.ROOT]: { href: '/', label: 'Demo' },
      [RouteHandlerTypes.PROMOTIONS]: {
        href: '/',
        label: 'Promotions',
      },
    },
  },
  authToken: '',
  isLoggedIn: false,
}

const initialState: DebugState = JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_KEY) as string
) || {
  isOpen: false,
  appName: AppName.FANDUEL_CASINO,
  baseUrl: process.env.REACT_APP_API_ENDPOINT,
  locationConfig: {
    value: 'WV',
  },
  placementQueryConfig: JSON.stringify(
    {
      brand: 'FANDUEL',
      containers: [
        {
          id: '678',
        },
      ],
    },
    undefined,
    2
  ),
  routeConfig: {
    routes: {
      [RouteHandlerTypes.LOGIN]: { href: '/login', label: 'Login' },
      [RouteHandlerTypes.VIEW_PROMOTION]: {
        href: '/:promoCode',
        label: 'Promotion',
      },
      [RouteHandlerTypes.JOIN]: { href: '/join', label: 'Join' },
      [RouteHandlerTypes.ROOT]: { href: '/', label: 'Demo' },
      [RouteHandlerTypes.PROMOTIONS]: {
        href: '/',
        label: 'Promotions',
      },
    },
  },
  authToken: '',
  isLoggedIn: false,
}

const initialContext: DebugOptions & DebugState = {
  ...initialState,
  onClose: () => null,
  onOpen: () => null,
  updateConfig: () => null,
  clearConfig: () => null,
}

export const DebugContext = React.createContext<DebugOptions & DebugState>(
  initialContext
)

export const DebugContextProvider: React.FC = ({ children }) => {
  const [debugState, setDebugState] = useState<DebugState>(initialState)

  const onOpen = () => {
    setDebugState({ ...debugState, isOpen: true })
  }

  const onClose = () => {
    setDebugState({ ...debugState, isOpen: false })
  }

  const updateConfig = (newDebugState: Partial<DebugState>) => {
    const {
      baseUrl,
      appName,
      placementQueryConfig,
      routeConfig,
      authToken,
      isLoggedIn,
      locationConfig,
    } = newDebugState

    setDebugState({
      ...debugState,
      isOpen: false,
      baseUrl: baseUrl || debugState.baseUrl,
      appName: appName || debugState.appName,
      locationConfig: locationConfig || debugState.locationConfig,
      placementQueryConfig:
        placementQueryConfig || debugState.placementQueryConfig,
      routeConfig: routeConfig || debugState.routeConfig,
      authToken: authToken || debugState.authToken,
      isLoggedIn: isLoggedIn || debugState.isLoggedIn,
    })

    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(
        {
          isOpen: false,
          baseUrl: isNullOrUndefined(baseUrl) ? debugState.baseUrl : baseUrl,
          appName: appName || debugState.appName,
          locationConfig: locationConfig || debugState.locationConfig,
          placementQueryConfig: isNullOrUndefined(placementQueryConfig)
            ? debugState.placementQueryConfig
            : placementQueryConfig,
          routeConfig: routeConfig || debugState.routeConfig,
          authToken: isNullOrUndefined(authToken)
            ? debugState.authToken
            : authToken,
          isLoggedIn: isNullOrUndefined(isLoggedIn)
            ? debugState.isLoggedIn
            : isLoggedIn,
        },
        undefined,
        2
      )
    )
    Cookies.set('X-Auth-Token', authToken || '', {
      secure: false,
      sameSite: 'Lax',
      expires: 1, // 1d
      domain: '.fndl.dev',
    })

    window.location.reload()
  }

  const clearConfig = () => {
    updateConfig(defaultState)
  }

  return (
    <DebugContext.Provider
      value={{ onOpen, onClose, updateConfig, clearConfig, ...debugState }}
    >
      {children}
    </DebugContext.Provider>
  )
}
