import { Event } from '@fanduel/cpp-fe-promotions'

export const eventsHandler = (event: Event) => {
  fakeAnalyticVendor(event)
}

export const fakeAnalyticVendor = (datalayer: Event) => {
  // eslint-disable-next-line no-console
  console.log(datalayer)
}
