import React from 'react'
import { Layout } from '../../components/Layout'
import { Wrapper } from '../../components/Wrapper'

import './LoginPage.scss'

export const LoginPage: React.FC = () => {
  return (
    <Layout>
      <Wrapper>
        <div className="login__wrapper">
          <h1>How to login</h1>

          <ol>
            <li>
              Login to{' '}
              <a
                href="https://sportsbook.qa.fndl.dev/"
                target="_blank"
                rel="noreferrer"
              >
                Sportsbook QA
              </a>
            </li>
            <li>Open up dev tools (right click inspect)</li>
            <li>
              In the console paste the following
              <pre className="login__code">
                <code>
                  {`function getCookie(name){var nameEQ = name + "=";var ca=document.cookie.split(';');for(var i=0;i<ca.length;i++){var c=ca[i];while(c.charAt(0)==' ') c=c.substring(1,c.length);if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);}return null;}getCookie('X-Auth-Token')`}
                </code>
              </pre>
            </li>
            <li>Take the value that is given and copy it</li>
            <li>
              In the demo, select debug at the bottom right and paste the auth
              token into the text area.
            </li>
            <li>Select save</li>
          </ol>
        </div>
      </Wrapper>
    </Layout>
  )
}
